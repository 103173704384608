import React from 'react';
import PropTypes from 'prop-types';

import {
  CardsContainer, StepContainer, Row, Label, SmallContent, Question, AnswerCard,
} from './styledComponents';
import ProgressBarVertical from './ProgressBarVertical';

const Step1Locations = ({
  treeLocations, treeLocations: { locations }, selectLocation, selectedLocations,
}) => {
  const progressBarHeight = () => {
    if (locations.length < 3) return 141;
    if (locations.length > 5) return 250;
    return 200;
  };
  return (
    <Row>
      <StepContainer>
        <ProgressBarVertical
          isActive={Object.keys(selectedLocations).length > 0}
          height={progressBarHeight()}
        />
        <SmallContent>
          <Question>{treeLocations.question}</Question>
          <Row>
            <CardsContainer smallMarginTop mobileCenter>
              {locations.map(({ slug, label }) => (
                <AnswerCard
                  key={slug}
                  slug={slug}
                  onClick={() => selectLocation(slug)}
                  isActive={selectedLocations.includes(slug)}
                >
                  <Label>
                    {label}
                  </Label>
                </AnswerCard>
              ))}
            </CardsContainer>
          </Row>
        </SmallContent>
      </StepContainer>
    </Row>
  );
};

Step1Locations.propTypes = {
  selectLocation: PropTypes.func,
  selectedLocations: PropTypes.arrayOf(PropTypes.string),
  treeLocations: PropTypes.shape({
    locations: PropTypes.array,
  }),
};

Step1Locations.defaultProps = {
  selectLocation() { },
  selectedLocations: [],
  treeLocations: {},
};

export default Step1Locations;
