import React from 'react';
import PropTypes from 'prop-types';

import {
  CardsContainer, customStyles, setTheme, StepContainer, Select, Row, SmallContent, Question,
} from './styledComponents';
import { multipleQuestions } from './tree';
import ProgressBarVertical from './ProgressBarVertical';

const Step1Multiple = ({
  createRange, slug, setSelectValue, selectedValue, isLast,
}) => (
  <Row>
    <StepContainer>
      <ProgressBarVertical
        isActive={Object.keys(selectedValue).length && selectedValue.value > 0}
        height={slug.includes('_multiple') ? 142 : 200}
        noLine={isLast && !!selectedValue}
      />
      <SmallContent>
        <Question>{multipleQuestions[slug.replace('_multiple', '')]}</Question>
        <Row>
          <CardsContainer smallMarginTop mobileCenter>
            <Select
              placeholder=""
              value={selectedValue}
              onChange={(valueSelected) => setSelectValue(valueSelected, slug)}
              options={createRange(1, 8)}
              styles={customStyles}
              theme={(theme) => setTheme(theme)}
            />
          </CardsContainer>
        </Row>
      </SmallContent>
    </StepContainer>
  </Row>
);

Step1Multiple.propTypes = {
  createRange: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  setSelectValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.shape({}).isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default Step1Multiple;
