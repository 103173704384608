import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import iconInformation from '../../assets/platform/information.png';

import {
  colors,
  mobileThresholdPixels,
  CardsContainer,
  StepContainer,
  Row,
  SmallContent,
  Question,
  Label,
  AnswerCard,
} from './styledComponents';
import ProgressBarVertical from './ProgressBarVertical';

const Tooltip = styled(ReactTooltip)`
  margin: 15px;
  width: 245px;
  border-radius: 4px;
  color: ${colors.white};
  opacity: 1 !important;
  background-color: ${colors.navy} !important;
  text-align: left;
  font-size: 13px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 203px;
    font-size: 12px;
  }
`;

const InfoImg = styled.img`
  height: 10px;
  width: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
`;

const Step1Lining = ({ selectLining, selectedLining }) => (
  <Row>
    <StepContainer>
      <ProgressBarVertical
        isActive={Object.keys(selectedLining).length > 0}
        height={20}
        noLine
      />
      <Tooltip place="right" type="dark" data-event="click" />
      <SmallContent>
        <Question>
          Y a-t-il une doublure ?
          <sup
            data-tip="La doublure est le tissu situé en plus à l’intérieur
            du vêtement souvent plus fin que la matière visible du vêtement."
          >
            <InfoImg src={iconInformation} alt="Information" />
          </sup>
        </Question>
        <Row>
          <CardsContainer smallMarginTop mobileCenter>
            <AnswerCard
              onClick={() => selectLining('haveLining')}
              isActive={selectedLining === 'haveLining'}
            >
              <Label>Oui</Label>
            </AnswerCard>
            <AnswerCard
              onClick={() => selectLining('noLining')}
              isActive={selectedLining === 'noLining'}
            >
              <Label>Non</Label>
            </AnswerCard>
            <AnswerCard
              onClick={() => selectLining('dontKnowLining')}
              isActive={selectedLining === 'dontKnowLining'}
            >
              <Label>Je ne sais pas</Label>
            </AnswerCard>
          </CardsContainer>
        </Row>
      </SmallContent>
    </StepContainer>
  </Row>
);

Step1Lining.propTypes = {
  selectLining: PropTypes.func,
  selectedLining: PropTypes.string,

};

Step1Lining.defaultProps = {
  selectLining() {},
  selectedLining: '',
};

export default Step1Lining;
