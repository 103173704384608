import featureSwitch from '../../services/featureSwitch';

import pantsPhotoNavy from '../../assets/platform/clothes/pants.navy.png';
import pantsPhotoWhite from '../../assets/platform/clothes/pants.white.png';
import coatPhotoNavy from '../../assets/platform/clothes/coat.navy.png';
import coatPhotoWhite from '../../assets/platform/clothes/coat.white.png';
import dressPhotoNavy from '../../assets/platform/clothes/dress.navy.png';
import dressPhotoWhite from '../../assets/platform/clothes/dress.white.png';
import weddingPhotoNavy from '../../assets/platform/clothes/wedding.navy.png';
import weddingPhotoWhite from '../../assets/platform/clothes/wedding.white.png';
import shirtPhotoNavy from '../../assets/platform/clothes/shirt.navy.png';
import shirtPhotoWhite from '../../assets/platform/clothes/shirt.white.png';
import skirtPhotoNavy from '../../assets/platform/clothes/skirt.navy.png';
import skirtPhotoWhite from '../../assets/platform/clothes/skirt.white.png';
import pulloverPhotoNavy from '../../assets/platform/clothes/sweater.navy.png';
import pulloverPhotoWhite from '../../assets/platform/clothes/sweater.white.png';
import shortsPhotoNavy from '../../assets/platform/clothes/swimsuit.navy.png';
import shortsPhotoWhite from '../../assets/platform/clothes/swimsuit.white.png';
import accessoriesPhotoNavy from '../../assets/platform/clothes/clutch.navy.png';
import accessoriesPhotoWhite from '../../assets/platform/clothes/clutch.white.png';

const clothes = [
  { name: 'Pantalon', slug: 'pant', images: { navy: pantsPhotoNavy, white: pantsPhotoWhite } },
  { name: 'Manteau & Veste', slug: 'mant', images: { navy: coatPhotoNavy, white: coatPhotoWhite } },
  { name: 'Robe', slug: 'robe', images: { navy: dressPhotoNavy, white: dressPhotoWhite } },
  { name: 'Robe de mariée', slug: 'mari', images: { navy: weddingPhotoNavy, white: weddingPhotoWhite } },
  { name: 'Haut', slug: 'haut', images: { navy: shirtPhotoNavy, white: shirtPhotoWhite } },
  { name: 'Jupe', slug: 'jupe', images: { navy: skirtPhotoNavy, white: skirtPhotoWhite } },
  { name: 'Pull & gilet', slug: 'pull', images: { navy: pulloverPhotoNavy, white: pulloverPhotoWhite } },
  { name: 'Lingerie & Maillot', slug: 'ling', images: { navy: shortsPhotoNavy, white: shortsPhotoWhite } },
  { name: 'Accessoires', slug: 'acce', images: { navy: accessoriesPhotoNavy, white: accessoriesPhotoWhite } },
];

const filteredClothes = clothes.filter((cloth) => featureSwitch('clothesSelector_clothes').includes(cloth.slug));

export default filteredClothes;
