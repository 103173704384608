import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels, fontWeight } from './styledComponents';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;
  width: ${({ large }) => large ? '345' : '224'}px;
  ${({ hasLogoImage, large }) => hasLogoImage ? `
    height: ${large ? '185' : '129'}px;
    padding: 44px 0px 36px;
  ` : `
    height: fit-content;
  `}
  margin: 0px 10px ${({ large }) => large ? '133' : '49'}px;
  background-color: ${({ isActive }) => isActive ? colors.navy : colors.white};
  box-shadow: 0px 1px 6px ${colors.blueGrey};
  font-family: Gotham;
  font-weight: ${fontWeight.medium};
  font-size: 15px;
  color: ${({ isActive }) => isActive ? colors.white : colors.navy};
  
  @media (max-width: ${mobileThresholdPixels}) {
    width: 127px;
    ${({ hasLogoImage }) => hasLogoImage && 'height: 70px; padding: 31px 0px 14px;'}
    margin: 0px 10px 10px;
    font-size: 12px;
  }
`;

const LogoImg = styled.img`
  width: 83px;
  height: 83px;
  margin-bottom: 33px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 46px;
    height: 46px;
    margin-bottom: 12px;
  }
`;

const Img = styled.img`
  width: inherit;
  height: 170px;
  object-fit: cover;
  object-position: 50% 50%;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 90px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 25px;
  }
`;

const Label = styled.h3`
  font-size: 18px;
  text-align: center;
  margin: 0px 70px 10px;
`;

const SubLabel = styled.span`
  font-weight: 200;
  font-size: 13px;
  text-align: center;
  margin: 0px 12px;
  font-family: Roboto;
`;

const CardSelector = ({
  slug, image, alt, label, subLabel, onClick, isActive, hasLogoImage, large,
}) => (
  <ButtonContainer
    slug={slug}
    onClick={() => onClick(slug)}
    isActive={isActive}
    hasLogoImage={hasLogoImage}
    large={large}
  >
    {subLabel && <Label>{label}</Label>}
    {hasLogoImage
      ? <LogoImg src={image} alt={alt} />
      : <Img src={image} alt={alt} />}
    {!subLabel && hasLogoImage && label}
    {!subLabel && !hasLogoImage && <TextContainer>{label}</TextContainer>}
    {subLabel && <SubLabel>{subLabel}</SubLabel>}
  </ButtonContainer>
);

CardSelector.propTypes = {
  slug: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  hasLogoImage: PropTypes.bool,
  large: PropTypes.bool,
};

CardSelector.defaultProps = {
  hasLogoImage: false,
  large: false,
  subLabel: null,
};

export default CardSelector;
